/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/socialStyle.js";

const useStyles = makeStyles(styles);

export default function SectionSocial() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.textCenter + " " + classes.sharingArea}>
          <GridContainer justify="center">
            <h3 className="font-link">Thank you for supporting us!</h3>
          </GridContainer>
          <Button color="twitter" href="https://twitter.com/HalfEatenEnt">
            <i className={classes.socials + " fab fa-twitter"} /> Tweet
          </Button>
          <Button color="discord" href="https://discord.gg/Qq4QEapB8G">
            <i className={classes.socials + " fab fa-discord"} /> Discord
          </Button>
          <Button color="danger" href="https://www.youtube.com/@half-eaten">
            <i className={classes.socials + " fab fa-youtube"} /> Youtube
          </Button>
          <Button color="discord" href="https://www.instagram.com/halfeatenentertainment/">
            <i className={classes.socials + " fab fa-instagram"} /> Instagram
          </Button>
          <Button color="warning" href="https://www.paypal.com/donate/?hosted_button_id=9FPAD3GL7UAUE">
            <i className={classes.socials + " fab fa-donate"} /> Donate
          </Button>
        </div>
      </div>
    </div>
  );
}
