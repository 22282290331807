import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/loreStyle.js";

import imagemain2 from "assets/img/VerticalCapsule.PNG";
import imagemain3 from "assets/img/bg_ToBeRevealed.png";

const useStyles = makeStyles(styles);

export default function SectionLore() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="lore">
          <div className={classes.title}>
            <h3 className="font-link">
              <b>Spam Text</b>
            </h3>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <img
                src={imagemain2}
                alt="lore slide"
                className={classes.imgRounded}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <span className="font-link">
                <p>
                  Spam Text is a word game that requires you to quickly think
                  about all the words in your dictionary that can match letters
                  on the screen. Think fast before the letters pile up to the
                  top of the screen.
                </p>
                <br />
                <h3 className="font-link">
                  <a href="https://store.steampowered.com/app/2113290/Spam_Text/">
                    Spam Text now on Steam
                  </a>
                </h3>
              </span>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="half-eaten-game">
            <div className={classes.title}>
              <h3 className="font-link">
                <b>Whats Next?</b>
              </h3>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <span className="font-link">
                  <p>
                    Our next game is already started into development. Our next
                    game will be a story rich, rougelite with all sorts of
                    hidden elements
                  </p>
                </span>
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <img
                  src={imagemain3}
                  alt="lore slide"
                  className={classes.imgRounded}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
