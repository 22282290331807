import { container, title, textbox } from "assets/jss/material-kit-react.js";

const loreStyle = {
  section: {
    padding: "70px 0",
  },
  container,
  textbox,
  title: {
    ...title,
    color: "#FFFFFF",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  imgRounded: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "6px !important",
  },
};

export default loreStyle;
