import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/aboutPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Collaborate with us</h2>
          <h4 className={classes.description}>
            Looking to help with the project? Have ideas? Join our
            <a href="https://discord.gg/Qq4QEapB8G"> Discord </a>, send a
            message with your suggestion/thoughts
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}
